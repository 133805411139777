.codex-editor{

  a{
    color: #388ae5;
  }

  // Headings

  h1.ce-header{
    @apply text-4xl font-semibold;
  }

  h2.ce-header{
    @apply text-3xl font-semibold;
  }

  h3.ce-header{
    @apply text-2xl font-semibold;
  }

  h4.ce-header{
    @apply text-xl font-semibold;
  }

  h5.ce-header{
    @apply text-lg font-semibold;
  }

  h6.ce-header{
    @apply text-base font-semibold;
  }

  .image-tool {
    --bg-color: #cdd1e0;
    --front-color: #388ae5;
    --border-color: #e8e8eb;
  }

  .sideimage-tool{
    @apply grid grid-cols-2 gap-4;
  
    .image-tool{
      @apply col-span-2 lg:col-span-1 order-1;

      &.image-tool--imageRight{
        @apply order-3;

      }
    }

    .sideimage-content{
      outline: none;
      display:inline-block;
      @apply w-full col-span-2 lg:col-span-1 h-full p-4 lg:p-2 rounded-md order-2;

      // if contenteditable is empty, show placeholder
      &:empty:not(:focus):before{
        content: "Начните писать... [SHIFT + ENTER - перенос строки]";
        color: #707684;
        font-weight: normal;
      }
    }
  }
  
    .image-tool__image {
      border-radius: 3px;
      overflow: hidden;
      margin-bottom: 10px;
    }
  
    .image-tool__image-picture {
        max-width: 100%;
        vertical-align: bottom;
        display: block;
      }
  
    .image-tool__image-preloader {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-size: cover;
        margin: auto;
        position: relative;
        background-color: var(--bg-color);
        background-position: center center;
      }
  
    .image-tool__image-preloader::after {
          content: "";
          position: absolute;
          z-index: 3;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          border: 2px solid var(--bg-color);
          border-top-color: var(--front-color);
          left: 50%;
          top: 50%;
          margin-top: -30px;
          margin-left: -30px;
          animation: image-preloader-spin 2s infinite linear;
          box-sizing: border-box;
        }
  
    .image-tool__caption[contentEditable="true"][data-placeholder]::before {
        position: absolute !important;
        content: attr(data-placeholder);
        color: #707684;
        font-weight: normal;
        display: none;
      }
  
    .image-tool__caption[contentEditable="true"][data-placeholder]:empty::before {
          display: block;
        }
  
    .image-tool__caption[contentEditable="true"][data-placeholder]:empty:focus::before {
          display: none;
        }
  
    .image-tool--empty .image-tool__image {
        display: none;
      }
  
    .image-tool--empty .image-tool__caption, .image-tool--loading .image-tool__caption {
        display: none;
      }
  
    .image-tool .cdx-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .image-tool .cdx-button svg {
        height: auto;
        margin: 0 6px 0 0;
      }
  
    .image-tool--filled .cdx-button {
        display: none;
      }
  
    .image-tool--filled .image-tool__image-preloader {
          display: none;
        }
  
    .image-tool--loading .image-tool__image {
        min-height: 200px;
        display: flex;
        border: 1px solid var(--border-color);
        background-color: #fff;
      }
  
    .image-tool--loading .image-tool__image-picture {
          display: none;
        }
  
    .image-tool--loading .cdx-button {
        display: none;
      }
  
    /**
     * Tunes
     * ----------------
     */
  
    .image-tool--withBorder .image-tool__image {
      border: 3px solid;
      @apply bg-neutral-100 border-primary;
    }
  
    .image-tool--watermark .image-tool__image {
      position: relative;

      &:after{
        content: '';
        position: absolute;
        bottom: 20px;
        right: 20px;
        background: url('../logo.svg') center center no-repeat;
        background-size: contain;
        opacity: 0.3;
        width: 140px;
        height: 27px;
        z-index: 9;
      }
    }
  
    .image-tool--withBackground .image-tool__image {
        padding: 15px;
        background: var(--bg-color);
      }
  
    .image-tool--withBackground .image-tool__image-picture {
          max-width: 60%;
          margin: 0 auto;
        }
  
    .image-tool--stretched .image-tool__image-picture {
          width: 100%;
        }
  
  @keyframes image-preloader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }  
}

.embed-article{
  width: 100%;
  margin: 10px auto;

  .input{
    width: 100%;
    @apply py-3 leading-none px-4 rounded-md border border-solid border-neutral-200 bg-white shadow-none transition-normal;

    &:focus{
      @apply border-neutral-400;
    }
  }

  .search-btn{
    cursor: pointer;
    margin-top: 10px;
    @apply transition-normal w-full block outline-none px-2 py-3 rounded-md border border-solid border-neutral-200 bg-white text-sm leading-none text-black text-center shadow-sm;
    
    &:hover{
      @apply bg-neutral-50;
    }
  }

  .article-wrapper{
    @apply grid grid-cols-12 gap-4 border border-solid border-neutral-100 bg-white p-4 lg:p-2 rounded-md shadow-sm;

    .article-img{
      @apply hidden lg:flex lg:col-span-3 flex-col justify-center items-center;

      img{
        width: 100%;
        @apply border border-solid border-neutral-100 rounded-md;
      }
    }

    .article-meta{
      @apply col-span-12 lg:col-span-9 flex flex-col justify-center;

      .article-category{
        @apply text-sm block text-primary font-normal leading-none mb-1;

        i{
          @apply not-italic text-neutral-400;
        }
      }

      .article-name{
        @apply text-base block text-black font-semibold leading-snug m-0;
      }

      .article-subtitle{
        @apply text-base block text-neutral-500 font-normal leading-snug;
      }
    }
  }
}